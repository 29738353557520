import { defineStore } from 'pinia';
import { store } from './store';
import main from '../../main';
import { chat } from "@/store/chat";
import {messages} from '@/composables/ReceiveMassage'
export const useSocketStore = defineStore({
  id: 'socket',
  state: () => ({
    isConnected: false,
    message: '',
    reconnectError: false,
    heartBeatInterval: 50000,
    heartBeatTimer: 0,
  }),
  actions: {
    SOCKET_ONOPEN(event) {
      if(!main) return false;
      const chat_store =chat()
      console.log(chat_store.chat_data)
      main.config.globalProperties.$socket = event.currentTarget;
      this.isConnected = true;
      this.heartBeatTimer = window.setInterval(() => {
        const message = 'Heartbeat message';
        if (this.isConnected) {
          main.config.globalProperties.$socket.sendObj({
            code: 200,
            msg: message,
          });

        }
      }, this.heartBeatInterval);
    },
    SOCKET_ONCLOSE(event) {
      this.isConnected = false;
      window.clearInterval(this.heartBeatTimer);
      this.heartBeatTimer = 0;
      console.log('The line is disconnected: ' + new Date());
      console.log(event);
    },
    SOCKET_ONERROR(event) {
      console.error(event);
    },
  arrayContainsObject (array ,object) {
      if(array.length>1) {
        return array?.some(item => item['RegD_id'] === object['RegD_id'])
      }
    },

    SOCKET_ONMESSAGE(evt) {
      const {ReceiveMessage,Receive_notifications,Receive_alerts,Receive_adminList, adminList,GetAlerts}  = messages()
      let data = evt
      if(data?.admin_chat_noti){
        Receive_notifications(data.admin_chat_noti)
      }
      if(data?.total_notifications>0){
        GetAlerts(data)
      }
      if(data?.chat_alerts){
        Receive_alerts(data.chat_alerts)
      }
      if(data?.chat_admin_list)
      {

       let val = this.arrayContainsObject(adminList.value,data.chat_admin_list[0])
       if(val){
         let obj_val = [data.chat_admin_list[0]]
         adminList.value.map(obj => obj_val.find(o => o.RegD_id === obj.RegD_id) || obj);
         return false
       }else{
        Receive_adminList(data.chat_admin_list)
        // adminList.value = data.chat_admin_list
        // console.log(data.chat_admin_list,'a2')
       }
       //   let val =  chat_store.adminList.includes(({RegD_id})=> RegD_id== data.chat_admin_list[0].RegD_id)
       // if(val)
       // {
       //   chat_store.adminList.replace(({RegD_id})=> RegD_id== data.chat_admin_list[0].RegD_id)
       //   return false
       // }
         // chat_store.adminList = data.chat_admin_list
      }

      if(data?.chat)
      {
        ReceiveMessage(data.chat[0])
      //  chat_store.chat_data.push()
      }


  //     let data = evt
  //    if(data?.online_connection)
  //   {
  //     online_offine(data)
  //   }

  //  if(data?.chat_dash_list)
  //  {
  //     let val = this.arrayContainsObject(adminList.value,data.chat_dash_list[0])
  //     if(val){
  //       let obj_val = [data.chat_dash_list[0]]
  //       adminList.value.map(obj => obj_val.find(o => o.User_id === obj.User_id) || obj);
  //       return false
  //     }else{
  //       adminList.value = data.chat_dash_list
  //     }
  //  }
  //  if(data?.chat)
  //  {

  //   ReceiveMessage(data.chat[0])
  //  }
  //  if(data?.total_notifications)
  //  {

  //   Receive_alerts(data)
  //  }
  //  if(data?.chat_alerts){

  //   Receive_notifications(data.chat_alerts)
  //  }
  },
    SOCKET_RECONNECT(count) {
      console.info('Message system reconnecting...', count);
    },
    SOCKET_RECONNECT_ERROR() {
      this.reconnectError = true;
    },
  },
});

export function useSocketStoreWithOut() {
  return useSocketStore(store);
}
