<template>
    <PageLoaderVue v-if="adminStore.btnLoading" />
    <div v-else-if="findMenu?.Can_view == true" class="col-xl-9 bg-w pd-30">
        <div class="row organization-dashboard-firstrow">
                <div class="col-md-4" >
                    <!--begin::Statistics Widget 5-->
                    <router-link to="/GMV_graph">
                    <a href="#" class="card  height-10p bg-info hoverable card-md-stretch mb-3 mb-md-4" style="height: 160px;">
                        <!--begin::Body-->
                        <div class="card-body">
                            <div class="row align-items-center">
                            <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg-->
                            <div class="col-7">
                             <img src="../../../public/assets/admin/media/organizations-dashboard-project-icon.png" />
                             </div>
                            <div class="col-5 text-right">
                            <h1 class="mt-5"></h1>
                            </div>
                            <div class="text-white text-left fw-bolder fs-1 mt-4 ">GMV</div>
                            </div>
                    </div>
                        <!--end::Body-->
                    </a>
                   </router-link>
                    <!--end::Statistics Widget 5-->
                </div>
                <div class="col-md-4" >
                    <!--begin::Statistics Widget 5-->
                    <router-link to="/Revenue_Graph">
                    <a href="#" class="card  height-10p bg-primary hoverable card-md-stretch mb-3 mb-md-4" style="height: 160px;">
                        <!--begin::Body-->
                        <div class="card-body">
                            <div class="row align-items-center">
                            <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg-->
                            <div class="col-7">
                                <img src="../../../public/assets/admin/media/organizations-dashboard-auditfirm-icon.png" />
                             </div>
                            <div class="col-5 text-right">
                                <h1 class="mt-5">{{d.DashboardData?.registered_companies}}</h1>
                            </div>
                            <div class="text-white text-left fw-bolder fs-1 mt-4 ">Revenue</div>
                            </div>
                    </div>
                        <!--end::Body-->
                    </a>
                   </router-link>
                    <!--end::Statistics Widget 5-->
                </div>

                <div class="col-md-4" >
                    <!--begin::Statistics Widget 5-->
                        <a href="#" @click="showModel('registered_companies')" class="card bg-success hoverable card-md-stretch mb-3 mb-md-4" style="height: 160px;">
                        <!--begin::Body-->
                        <div class="card-body">
                            <div class="row align-items-center">
                            <!--begin::Svg Icon | path: icons/duotune/ecommerce/ecm002.svg-->
                            <div class="col-7">
                                <img src="../../../public/assets/admin/media/organizations-dashboard-auditfirm-icon.png" />
                             </div>
                            <div class="col-5 text-right">
                                <h1 class="mt-5">{{d.DashboardData?.active_accounts}}</h1>
                            </div>
                            <div class="text-white text-left fw-bolder fs-1 mt-2 ">Registered Companies</div>
                            </div>
                    </div>
                        <!--end::Body-->
                    </a>
                    <!--end::Statistics Widget 5-->
                </div>
            </div>
            <div>
  <div class="col-md-12 row organization-dashboard-firstrow" style="width: auto;">
    <div class="col-md-6" >
        <div class="card table-card" style="flex: 1; background: #f7f7f7 !important; border: 1px solid #d3d3d3; margin-bottom: 22px; margin-top: 10px;">
            <table class="table">
            <tbody>
                <!-- Rows for the left card -->
        <router-link :to="{ name: 'projects', params: { id: '2' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1; padding-left:  2%; padding-right:  2%;">
                    <div>
                        <div class="text-black fw-bolder mb-2">Active Projects Status</div>
                    </div>
                    <div>
                        <div class="text-black fw-bolder mb-2">{{d.DashboardData?.active_projects}}</div>
                    </div>
                </td>
            </tr>
        </router-link>
        <router-link :to="{ name: 'projects', params: { id: '3' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1; padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Completed Projects Status</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{ d.DashboardData?.completed_projects}}</div>
                </div>
                 </td>
            </tr>
        </router-link>
        <div>
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1; padding-left:  2%; padding-right:  2%;">
                    <div>
                            <div class="text-black fw-bolder mb-2">New Signups</div>
                    </div>
                    <div>
                            <div class="text-black fw-bolder mb-2">{{d.DashboardData?.new_signup_accounts}}</div>
                    </div>
                </td>
            </tr>
        </div>
            <div>
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1;padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Deactivated account</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{d.DashboardData?.deactivated_signup_accounts}}</div>
                </div>
                </td>
            </tr>
        </div>
                <!-- Repeat this structure for other rows on the left card -->
            </tbody>
            </table>
        </div>
    </div>
          <!-- Right Card -->
    <div class="col-md-6" >
  <div class="card table-card" style="flex: 1; background: #f7f7f7 !important; border: 1px solid #d3d3d3; margin-bottom: 22px; margin-top: 10px;">
    <table class="table">
      <tbody>
                <!-- Rows for the right card -->
        <router-link :to="{ name: 'audit-Request', params: { id: '1' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1;padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Active Audit Status</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{d.DashboardData?.active_audits}}</div>
                </div>
                </td>
            </tr>
        </router-link>
        <router-link :to="{ name: 'audit-Request', params: { id: '3' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1;padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Completed Audit Status</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{d.DashboardData?.completed_audits}}</div>
                </div>
                </td>
            </tr>
        </router-link>
        <router-link :to="{ name: 'disputes', params: { id: '2' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1;padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Active Disputes</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{d.DashboardData?.active_disputes}}</div>
                </div>
                </td>
            </tr>
        </router-link>
        <router-link :to="{ name: 'disputes', params: { id: '3' }}">
            <tr style="display: flex;">
                <td style="display: flex; justify-content: space-between; flex: 1;padding-left:  2%; padding-right:  2%;">
                <div>
                    <div class="text-black fw-bolder mb-2">Completed Disputes</div>
                </div>
                <div>
                    <div class="text-black fw-bolder mb-2">{{d.DashboardData?.completed_disputes}}</div>
                </div>
                </td>
            </tr>
        </router-link>
                <!-- Repeat this structure for other rows on the right card -->
            </tbody>
            </table>
            </div>
        </div>
    </div>
</div>

        <div class="row" >
            <div class="col-md-6">
              <div class="card bg-white p-2" style="border: 1px solid #d8d8d8; border-radius: 5px; height: 100%;">
                <h3 class="card-title fw-bolder text-dark text-center pt-3">
                  Supplier Audit Overview
                </h3>
                <div class="mt-3 mb-3">
                  <BarChart></BarChart>
                </div>
              </div>
            </div>
            <div class="col-md-6">
            <div class="card bg-white p-2" style="border: 1px solid #d8d8d8; border-radius: 5px; height: 100%;">
                <h3 class="card-title fw-bolder text-dark text-center pt-3">
                  Top audit firm proformance
                </h3>
              <div class="mt-3 mb-3">
                <ReviewBarChart></ReviewBarChart>
               <!-- <LineChart></LineChart> -->
              </div>
            </div>
        </div>
            <!-- <div class="col-md-6 bg-white bar_cart"  style="border:1px solid #d8d8d8;">

            </div> -->
        </div>
            <div class="row gy-5 g-xl-8 dashboard-stats-2ndrow mt-3">
                <div class="col-xl-4" v-if="false">
                    <!--begin::List Widget 4-->
                    <div class="card card-xl-stretch mb-xl-8">
                        <!--begin::Beader-->
                        <div class="card-header border-0 py-5">
                            <h3 class="card-title align-items-start flex-column">
                                <span class="card-label fw-bolder fs-3 mb-1">Overview</span>
                                <span class="text-muted fw-bold fs-7">Projects Statistics</span>
                            </h3>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body p-0 d-flex flex-column">
                            <!--begin::Stats-->
                            <div class="card-p pt-5 bg-body flex-grow-1">
                                <!--begin::Row-->
                                <div class="row g-0">
                                    <!--begin::Col-->
                                    <div class="col mr-8">
                                        <!--begin::Label-->
                                        <div class="fs-7 text-muted fw-bold">Average Spend</div>
                                        <!--end::Label-->
                                        <!--begin::Stat-->
                                        <div class="d-flex align-items-center">
                                            <div class="fs-4 fw-bolder">${}</div>

                                            <!--end::Svg Icon-->
                                        </div>
                                        <!--end::Stat-->
                                    </div>
                                    <!--end::Col-->
                                    <!--begin::Col-->
                                    <div class="col">
                                        <!--begin::Label-->
                                        <div class="fs-7 text-muted fw-bold">Admin Services Charges</div>
                                        <!--end::Label-->
                                        <!--begin::Stat-->
                                        <div class="fs-4 fw-bolder">${}</div>
                                        <!--end::Stat-->
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                                <!--begin::Row-->
                                <div class="row g-0 mt-8">
                                    <!--begin::Col-->
                                    <div class="col mr-8">
                                        <!--begin::Label-->
                                        <div class="fs-7 text-muted fw-bold">Taxes 2022</div>
                                        <!--end::Label-->
                                        <!--begin::Stat-->
                                        <div class="fs-4 fw-bolder">${}</div>
                                        <!--end::Stat-->
                                    </div>
                                    <!--end::Col-->
                                    <!--begin::Col-->
                                    <div class="col">
                                        <!--begin::Label-->
                                        <div class="fs-7 text-muted fw-bold">Total Spend</div>
                                        <!--end::Label-->
                                        <!--begin::Stat-->
                                        <div class="d-flex align-items-center">
                                            <div class="fs-4 fw-bolder">${}</div>
                                        </div>
                                        <!--end::Stat-->
                                    </div>
                                    <!--end::Col-->
                                </div>
                                <!--end::Row-->
                            </div>
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List Widget 4-->
                </div>
                <!--begin::Col-->
                <div class="col-xl-6">
                    <!--begin::List Widget 2-->
                    <div class="card card-xl-stretch mb-xl-8">
                        <!--begin::Header-->
                        <div class="card-header border-0">
                            <h3 class="card-title fw-bolder text-dark">Active Projects Status </h3>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-2" style="height: 330px;overflow: auto;">
                            <!--begin::Item-->
                            <div class="d-flex align-items-center mb-7" v-for="(l ,index) in d.ActiveProjects" :key="index">
                                <!--begin::Text-->
                                <div class="flex-grow-1">
                                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{l.project_title}}</a>
                                    <span class="text-muted d-block fw-bold"><b>{{l.project_title}}:</b> {{l.RegD_comp_name}}</span>
                                </div>
                                <div class="flex-grow-1 text-right">
                                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{l.Completed_persontage}}</a>
                                    <span class="text-muted d-block fw-bold">Completed</span>
                                </div>
                                <!--end::Text-->
                            </div>
                            <!--end::Item-->
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List Widget 2-->
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-xl-6">
                    <!--begin::List Widget 2-->
                    <div class="card card-xl-stretch mb-xl-8">
                        <!--begin::Header-->
                        <div class="card-header border-0">
                            <h3 class="card-title fw-bolder text-dark">Registration Requests</h3>
                        </div>
                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body pt-2" style="height: 330px;overflow: auto;">
                            <!--begin::Item-->
                            <div class="d-flex align-items-center mb-7" v-for="(l,index) in d.RegistrationRequests" :key="index">
                                <!--begin::Text-->
                                <div class="symbol symbol-50px me-5">
                                    <img :src="path+l.Company_logo" class="" alt="">
                                </div>
                                <div class="flex-grow-1">
                                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{l.Company_name}}</a>
                                    <span class="text-muted d-block fw-bold"><b>{{l.Account_type_name}}</b></span>
                                </div>
                                <!-- <div class="flex-grow-1 text-right">
                                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">View</a>
                                </div> -->
                                <!--end::Text-->
                            </div>
                        </div>
                        <!--end::Body-->
                    </div>
                    <!--end::List Widget 2-->
                </div>
            </div>
    </div>
    <div v-else style="height:80vh" class="col-xl-9 bg-w pd-30">
    <h1 class="text-center">Dashboard</h1>
    </div>

    <teleport to="body">
            <CompanyPartners :tabName="tabName" v-if="open_model" :document="document"  @closeModel="closeModel" />
</teleport>
</template>

<script setup>
import rolePermission from '@/composables/rolePermission';
/* eslint-disable */
import "vue3-multiselect-checkboxed/style.css";
import {useAuthStore} from '@/store/auth.store';
import ReviewBarChart from "@/components/Chart/ReviewBarChart.vue";
import {computed, onMounted,ref} from 'vue';
import CompanyPartners from '../../components/Modal/CompanyPartners.vue';
import LineChart from "@/components/Chart/LineChart.vue";
import BarChart from "@/components/Chart/BarChart.vue";
import { Vue3MultiselectCheckboxed } from "vue3-multiselect-checkboxed";
import { useadminUsersStore } from '@/store/adminUsers.store';
import { useRoute } from 'vue-router';
import PageLoaderVue from '@/components/Global/PageLoader.vue';

const store = useAuthStore()
const adminStore = useadminUsersStore()
// const permission = computed(() => rolePermission())
const {  } = computed(() => rolePermission())

const route = useRoute()



const path = process.env.VUE_APP_Images
let tabName = ref('')
let per_role = ref('')
let open_model = ref('')
let findMenu = computed(() => {
  const currentPath = route.path;
  const pathWithoutId = currentPath.replace(/\/\d+$/, '');

  return adminStore.SideBarMenuList?.find((el => el.Menue_location == pathWithoutId.slice(1)))
});

const result = ref([]);
const setResult = (val) => {
  result.value = [...val];
};

const  styleOption ={
        width: "100%",
        height: "385px"
      }

const countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "of suppliers", number: "004" },
  { code: "AF", code3: "AFG", name: "of completed audited", number: "006" },
  { code: "AL", code3: "ALB", name: "of audited suppliers", number: "008" },
  { code: "DZ", code3: "DZA", name: "of not audited suppliers", number: "012" },
  { code: "AS", code3: "ASM", name: "of In-process audit", number: "016" },
  { code: "AS", code3: "ASM", name: "of Re-audited", number: "016" },
  { code: "AF", code3: "AFG", name: "review.", number: "006" },
];

const d = computed(()=>{
    return store.dashboard_data
})



const showModel = (name)=>{
    tabName.value = name
    open_model.value = true
}

const closeModel =()=>{
    open_model.value=false
}
onMounted(()=>{
    store.GetDashboardData()
})

</script>

<style scoped>
.arabic #app .card .card-header {
  justify-content: end !important;
}

.custom_options > div{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.data_filter
{
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.data_filter span{
    background: white;
  display: inline-block;
    width: 20%;
    height: 33px;
    border: 1px solid #ddd;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin-top:10px;
}
.bar_cart{
  padding:50px 10px 20px;
}

</style>
<style>
.dp__main{
  max-width: 300px !important;
}
.wayfmf6wh3{
  min-width: 109px !important;
  height: 33px !important;
}
.qf5k5qmpv5{
  width: 200px;
}
.table-card table tbody tr:hover {
    background-color: lightgray;
  }
.table {
    margin-bottom: 0;
}
</style>
