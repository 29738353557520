import API from '@/services/API'
import { defineStore } from 'pinia'
// import useToast from '@/composables/useToast.js'

export const useregistrationsStore = defineStore('registrationstore',{
    state: () => ({
        registrationsList : [],
        RegisteredList: [],
        ProfileView:{},
        CertificateTemplateView:{},
        AuditReportTemplateView:{},
        totalPages: 0,
        loader : false,
        regListLoader : false,
        profileLoader : false,
        certificateTemplateLoader : false,
        auditReportTemplateLoader : false,
        
    }),

    actions:{
        async loadRegistrationListing(data){
            try{
                this.loader = true
                const response = await API.get("api/registration/get-by-pagination",data)
                this.loader = false
                if(response.status == 200 ){
                    this.totalPages = Math.ceil(response.data.TotalRows/data.RowNo)
                    this.registrationsList = response.data.Pagination              
                }
                return response
            }catch(error){
                this.loader=false
            }
         
        },

      
        async loadRegisteredList(data) {
            this.regListLoader = true
            const response = await API.get("api/registration/get-total-reglist",data)
            this.regListLoader = false
            if( response.status == 200 ){
                this.RegisteredList = response.data
            }
        }, 

        
        async loadProfileView(RegD_id) {
            this.profileLoader = true
            const response = await API.get(`api/RegistrationSetting/getEditComp/${RegD_id}`)
            this.profileLoader = false
            if(response.status == 200){
              this.ProfileView = response.data
              console.log("data",response.data)
            }
        },

        async loadCertificateTemplateView(RegD_id, accountType) {
            this.certificateTemplateLoader = true
            const certificates = await API.get(`api/certificate/GetCertificateByRegD_id?RegD_id=${RegD_id}&by_audit_firm=false`)
            this.CertificateTemplateView = {...this.CertificateTemplateView, RegD_id}
            if(certificates.status == 200){
              this.CertificateTemplateView = {...this.CertificateTemplateView, certificates: certificates.data}
              console.log("certificates",certificates.data)
            }
            const templates = await API.get(`api/certificate/GetCertificateTemplates?account-type=${accountType}`)
            if(templates.status == 200){
                this.CertificateTemplateView = {...this.CertificateTemplateView, templates: Object.values(templates.data)}
                console.log("certificate templates", Object.values(templates.data))
              }
              const assigned = await API.get(`api/certificate/GetOrgCertificateTemplates?RegD_id=${RegD_id}`)
              if(assigned.status == 200){
                  this.CertificateTemplateView = {...this.CertificateTemplateView, assigned: Object.values(assigned.data)}
              } else {
                  alert(assigned.data)
              }
              this.certificateTemplateLoader = false
        },

        async loadAuditReportTemplateView(RegD_id, accountType) {
            this.auditReportTemplateLoader = true
            this.AuditReportTemplateView = {...this.AuditReportTemplateView, RegD_id}
            const compliances = await API.get(`api/compliance/all-compliance?RegD_id=${RegD_id}`)
            this.AuditReportTemplateView = {...this.AuditReportTemplateView, RegD_id}
            if(compliances.status == 200){
              this.AuditReportTemplateView = {...this.AuditReportTemplateView, compliances: compliances.data}
              console.log("compliances",compliances.data)
            }
            const templates = await API.get(`api/audit/get-audit-report-templates?account-type=${accountType}`)
            if(templates.status == 200){
                this.AuditReportTemplateView = {...this.AuditReportTemplateView, templates: Object.values(templates.data)}
                console.log("auditReport templates", Object.values(templates.data))
              }
              const assigned = await API.get(`api/audit/get-organization-auditreport-templates?RegD_id=${RegD_id}`)
              if(assigned.status == 200){
                  this.AuditReportTemplateView = {...this.AuditReportTemplateView, assigned: Object.values(assigned.data)}
              } else {
                  alert(assigned.data)
              }
              this.auditReportTemplateLoader = false
        },

        async assignCertificateTemplate(data) {
            const sanitizedData = {
                ...data,
                certificateId: data.certificateId === "" ? null : data.certificateId
            };
            const response = await API.post(`api/certificate/assignCertificateTemplate`, sanitizedData, true)
            if(response.status == 200){
                if (!('assigned' in this.CertificateTemplateView)) {
                    this.CertificateTemplateView.assigned = [];
                  }
                this.CertificateTemplateView.assigned.push(response.data)
            } 
            else if(response.status == 202){
                  const currCertTempIndex = this.CertificateTemplateView.assigned.findIndex(_=> parseInt(_.RegD_id) === parseInt(sanitizedData.RegD_id) && _.Certificate_id === sanitizedData.certificateId)
                  if(currCertTempIndex > -1) {
                    this.CertificateTemplateView.assigned[currCertTempIndex] = response.data;
                    this.CertificateTemplateView.assigned[currCertTempIndex].flash = {
                        state: true,
                        alert: 'primary'
                    }
                    setTimeout(() => {
                        this.CertificateTemplateView.assigned[currCertTempIndex].flash = {
                            state: false,
                        }
                    }, 1000);
                }
            } 
            else {
                const currCertTempIndex = this.CertificateTemplateView.assigned.findIndex(_ => (parseInt(_.RegD_id) === parseInt(sanitizedData.RegD_id) && _.Certificate_id === sanitizedData.certificateId && _.Template_slug === sanitizedData.templateSlug))
                if(currCertTempIndex > -1) {
                    this.CertificateTemplateView.assigned[currCertTempIndex].flash = {
                        state: true,
                        alert: 'warning'
                    }
                    setTimeout(() => {
                        this.CertificateTemplateView.assigned[currCertTempIndex].flash = {
                            state: false
                        }
                    }, 1000);
                }
            }
        },

        async assignAuditReportTemplate(data) {
            const sanitizedData = {
                ...data,
                complianceId: data.complianceId === "" ? null : data.Compliance_id
            };
            const response = await API.post(`api/audit/assign-auditreport-template`, sanitizedData, true)
            if(response.status == 200){
                if (!('assigned' in this.AuditReportTemplateView)) {
                    this.AuditReportTemplateView.assigned = [];
                  }
                this.AuditReportTemplateView.assigned.push(response.data)
            } 
            else if(response.status == 202){
                  const currAudRptTempIndex = this.AuditReportTemplateView.assigned.findIndex(_=> parseInt(_.RegD_id) === parseInt(sanitizedData.RegD_id) && _.Compliance_id === sanitizedData.complianceId)
                  if(currAudRptTempIndex > -1) {
                    this.AuditReportTemplateView.assigned[currAudRptTempIndex] = response.data;
                    this.AuditReportTemplateView.assigned[currAudRptTempIndex].flash = {
                        state: true,
                        alert: 'primary'
                    }
                    setTimeout(() => {
                        this.AuditReportTemplateView.assigned[currAudRptTempIndex].flash = {
                            state: false,
                        }
                    }, 1000);
                }
            } 
            else {
                const currAudRptTempIndex = this.AuditReportTemplateView.assigned.findIndex(_ => (parseInt(_.RegD_id) === parseInt(sanitizedData.RegD_id) && _.Compliance_id === sanitizedData.complianceId  && _.Template_slug === sanitizedData.templateSlug))
                console.log('currAudRptTempIndex :>> ', currAudRptTempIndex);
                if(currAudRptTempIndex > -1) {
                    this.AuditReportTemplateView.assigned[currAudRptTempIndex].flash = {
                        state: true,
                        alert: 'warning'
                    }
                    setTimeout(() => {
                        this.AuditReportTemplateView.assigned[currAudRptTempIndex].flash = {
                            state: false
                        }
                    }, 1000);
                }
            }
        },

        async unassignCertificateTemplate(id) {
            const response = await API.delete(`api/certificate/unassignCertificateTemplate`, {id}, true)
            if(response.status == 200){
                const currCertTempIndex = this.CertificateTemplateView.assigned.findIndex(_=> parseInt(_.id) === parseInt(id))
                if(currCertTempIndex > -1) {
                  this.CertificateTemplateView.assigned[currCertTempIndex].flash = {
                      state: true,
                      alert: 'danger'
                  }
                  setTimeout(() => {
                    this.CertificateTemplateView.assigned = this.CertificateTemplateView.assigned.filter(_ => _.id !== id)
                  }, 1000);
              }
            } 
        },

        async unassignAuditReportTemplate(id) {
            const response = await API.delete(`api/audit/unassign-auditreport-template`, {id}, true)
            if(response.status == 200){
                const currCertTempIndex = this.AuditReportTemplateView.assigned.findIndex(_=> parseInt(_.id) === parseInt(id))
                if(currCertTempIndex > -1) {
                  this.AuditReportTemplateView.assigned[currCertTempIndex].flash = {
                      state: true,
                      alert: 'danger'
                  }
                  setTimeout(() => {
                    this.AuditReportTemplateView.assigned = this.AuditReportTemplateView.assigned.filter(_ => _.id !== id)
                  }, 1000);
              }
            } 
        },

        async getOrgReviewGraphData(payload) {
            this.profileLoader = true
            const response = await API.get(`api/general/admin_review_graph`,payload)
            this.profileLoader = false
            if(response.status == 200){
                this.orginization_data = response.data
            }

            return response
        },

        // async loadRegisteredList(id) {
        //     const response = await API.get(`api/registration/get-registerd/?RegD_id=${id}`)
        //     if( response.status == 200 ){
        //         this.RegisteredList = response.data
        //     }
        // }, 
    }

})